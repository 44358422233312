<template>
  <div class="col-12 col-sm-6">
    <div class="login--col">
      <h2>
        {{ $t("login.login_reset_password_title") }}
      </h2>
      <p>
        {{ $t("login.login_reset_password_description") }}
      </p>
      <form
        action=""
        @submit.prevent="sendResetEmail"
        class="login--form login--form--fPw"
      >
        <input
          type="text"
          :placeholder="$t('login.login_email')"
          class="login--form--input"
          v-model="email"
        />
        <button class="login--form--submit btn--blk">
          {{ $t("login.login_reset_password_button") }}
        </button>
      </form>
      <div class="row">
        <div class="col-12">
          <p v-if="successMsg">{{ successMsg }}</p>
          <div class="form--input--error" v-if="errorMsg.length">
            <ul>
              <li v-for="(error, index) in errorMsg" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "resetForm",
  data() {
    return {
      email: "",
      successMsg: "",
      errorMsg: []
    };
  },
  methods: {
    ...mapActions("common", ["setUser", "setToken"]),

    async sendResetEmail() {
      try {
        this.errorMsg = [];

        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/sendResetPassword/`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: this.email })
          }
        );

        if (!response.ok || response.status == 401) {
          throw new Error(this.$t("login.login_error_reset_email_not_found"));
        }

        this.setUser(null);
        this.setToken(null);
        this.successMsg = this.$t("login.login_error_reset_check_inbox");
      } catch (error) {
        this.errorMsg.push(error.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
